<template>
    <sui-message success>
        <sui-message-header v-if="title">{{title}}</sui-message-header>
        <ul v-if="reasons && reasons.length !== 0">
            <li v-for="r in reasons" v-bind:key="r" v-html="desc">{{r}}</li>
        </ul>
    </sui-message>
</template>

<script>
    export default {
        name: "ErrorMessage",
        props: {
            reasons: Array,
            title: String
        }
    }
</script>

<style scoped>

</style>