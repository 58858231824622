<template>
    <div id="edit-user">
        <h2 is="sui-header" class="dividing">Edit User - ID:{{$route.params.uid}}</h2>

        <sui-form :loading="loading" :error="errorTitle !== ''" :success="successTitle !== ''">
            <success-message :title="successTitle" :reasons="successList"></success-message>
            <error-message :title="errorTitle" :reasons="errorList"></error-message>
            <access-form :user="user"></access-form>

            <sui-divider></sui-divider>

            <sui-button type="submit" color="blue" @click.prevent="update">Update</sui-button>
            <sui-button floated="right" @click.prevent="$router.push('/dashboard/access')">Cancel</sui-button>
        </sui-form>
    </div>

</template>

<script>

    import axios from "axios";
    import AccessForm from "@/views/dashboard/access/AccessForm";

    import SuccessMessage from "@/components/SuccessMessage";
    import ErrorMessage from "@/components/ErrorMessage";

    export default {
        name: "AccessEdit",
        components: {SuccessMessage, ErrorMessage, AccessForm},
        data: function () {
            return {
                user: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    contactNumber: "",
                    alternateContactNumber: "",
                    company: "",
                    jobTitle: "",
                    street: "",
                    city: "",
                    zipCode: "",
                    country: "",
                    state: "",
                    status: "Active",
                    associatedRoles: "SG",

                    errorFirstName: false,
                    errorLastName: false,
                    errorEmail: false,
                },
                loading: true,

                errorTitle: "",
                errorList: [],

                successTitle: "",
                successList: [],
            }
        },
        created: async function () {
            try {
                const response = await axios({
                    method: "POST",
                    url: "/api/user/info",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),
                        uid: this.$route.params.uid
                    }
                });
                console.log(response.data);
                this.loading = false;
                this.user = {
                    firstName: response.data.user.firstName,
                    lastName: response.data.user.lastName,
                    email: response.data.user.email,
                    contactNumber: response.data.user.metadata.contactNumber,
                    alternateContactNumber: response.data.user.metadata.alternateContactNumber,
                    company: response.data.user.metadata.company,
                    jobTitle: response.data.user.metadata.jobTitle,
                    street: response.data.user.metadata.street,
                    city: response.data.user.metadata.city,
                    zipCode: response.data.user.metadata.zipCode,
                    country: response.data.user.metadata.country,
                    state: response.data.user.metadata.state,
                    status: response.data.user.status,
                    associatedRoles: response.data.user.role,

                    errorFirstName: false,
                    errorLastName: false,
                    errorEmail: false,
                }


            } catch (e) {
                this.errorTitle = "Cannot load user profile";
                this.errorList = [];
                this.errorList.push("Network connection issue.");
            }
        },
        methods: {
            update: function () {
                this.loading = true
                axios({
                    method: "POST",
                    url: "/api/user/update",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        uid: this.$route.params.uid,

                        email: this.user.email,
                        firstName: this.user.firstName,
                        lastName: this.user.lastName,
                        status: this.user.status,
                        role: this.user.associatedRoles,
                        metadata: {
                            contactNumber: this.user.contactNumber,
                            alternateContactNumber: this.user.alternateContactNumber,
                            company: this.user.company,
                            jobTitle: this.user.jobTitle,
                            street: this.user.street,
                            city: this.user.city,
                            zipCode: this.user.zipCode,
                            country: this.user.country,
                            state: this.user.state,
                        },
                    }
                }).then((response) => {
                    console.log(response)
                    this.successTitle = "User Profile Updated!";
                    this.errorTitle = "";
                    this.errorList = [];
                }).catch(() => {
                    this.errorTitle = "Cannot update user profile";
                    this.errorList = [];
                    this.errorList.push("Network connection issue.");
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>

</style>
